import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import useWindowSize from "@utils/useWindowSize";
import tw from "twin.macro";

import Layout from '@layouts/index';
import SEO from "@components/seo";
import ThankYouInfo from "@components/thank-you/info";
import ThankYouImages from "@components/thank-you/images";

const Wrapper = tw.div`md:flex`;

const ThankYouPage = () => {
  const intl = useIntl();
  const { width } = useWindowSize();

  return (
    <Layout hideFooter>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Contact' })}
        description={intl.formatMessage({ id: 'SEO.Description.Contact' })}
      />
      <Wrapper>
        <ThankYouInfo width={ width } />
        <ThankYouImages width={ width } />
      </Wrapper>
    </Layout>
  );
};

export default ThankYouPage;
