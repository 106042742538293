import React from "react";
import { pagesRoutes } from "@utils/variables";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

import LinkPrimary from "@components/links/primary";

const Wrapper = tw.div`
  relative ml-40 mr-10
  md:w-1/2 md:mx-0
`;

const Header = styled(motion.div)`
  ${tw`
    max-w-325 mb-10 text-xl leading-33 -tracking-016
    md:max-w-none md:mt-140 md:mb-60 md:ml-10 md:text-2xl
    lg:relative lg:top-280 lg:mt-130 lg:ml-0 lg:mb-30 lg:text-4xl lg:leading-60 lg:-tracking-028
    lg:opacity-0
  `}
  
  ${down('md')} {
    transform: none !important;
  }
`;

const DescriptionWrapper = tw(motion.div)`flex mb-60 lg:mb-80 lg:opacity-0`;

const Description = styled.p`
  ${tw`
    text-sm text-second leading-20 -tracking-021
    md:-tracking-027
    lg:text-md lg:leading-30
  `}
  
  b {
    ${tw`font-pangramBold`}
  }
`;

const LinkWrapper = tw(motion.div)`lg:opacity-0`;

const DescriptionCharacter = tw.span`
  font-pangramBold mr-15 text-sm text-second leading-32 -tracking-021
  md:-tracking-03
  lg:min-w-40 lg:mx-10 lg:text-lg
`;

const ThankYouInfo = ({ width }: { width: number }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header
        animate={ width >= 1024 && { y: -280, opacity: 1 }}
        transition={{
          opacity: { duration: .542, delay: .242, ease: 'linear' },
          y: { duration: .875, delay: .542, ease: [.84, 0, .16, 1] }
        }}
      >
        {t('Thank you:Heading1')}
        <br/>
        {t('Thank you:Heading2')}
      </Header>
      <DescriptionWrapper
        animate={ width >= 1024 && { opacity: 1 }}
        transition={{ duration: .6, delay: 1.3, ease: 'linear' }}
      >
        <DescriptionCharacter>
          {t('Description Character')}
        </DescriptionCharacter>
        <Description>
          {t('Thank you:Description1')}
          <br/>
          {t('Thank you:Description2')}
          <br/><br/>
          <b>{t('Thank you:Description3')}</b>
        </Description>
      </DescriptionWrapper>
      <LinkWrapper
        initial={ width >= 1024 && 'hidden'}
        animate={ width >= 1024 && 'visible' }
        transition={{
          opacity: { duration: .917, delay: 1.5, ease: 'linear' },
          rotate: { duration: .917, delay: 1.5, ease: [.88, .14, .12, .86] }
        }}
        variants={{
          'visible': { opacity: 1, rotate: 0 },
          'hidden': { rotate: -8 }
        }}
      >
        <LinkPrimary path={pagesRoutes.home} text={'Buttons:Back to home page'} />
      </LinkWrapper>
    </Wrapper>
  );
};

export default ThankYouInfo;
